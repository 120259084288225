import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';

import PageContainer from 'src/components/PageContainer';

import { ButtonWithIcon, Form, SocialButtons, FormControls } from 'src/components/StyledCommon';
import { ReactComponent as GoogleIcon } from 'src/assets/icons/social/google.svg';

import store from 'src/store';
import api, { ChronosAuthApi, authWithGoogle } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';
import {gtmSend} from "../../utils";
import { Avatar, Button, Input, InputContainer, TInputMode } from 'src/libs';

export default ReactMemo(function LogIn() {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState<string>('');
  const [passwordMode, setPasswordMode] = React.useState<TInputMode>('normal');
  const [passwordMessage, setPasswordMessage] = React.useState<string>('');
  const { t } = useTranslation();

  // React.useEffect(() => {
  //   console.log('user data -', store.auth.User)
  // }, []);

  const greeting = t("chronos.auth.login.welcomeBack", { firstName: store.auth.User?.firstName ? `, ${store.auth.User?.firstName}!` : `!` });
  const channel = store.auth.User?.type === 'phone' ? store.auth.User?.phoneNumber : store.auth.User?.email;
  const isAuthWithCreditionals = ['phone', 'email'].includes(store.auth.User?.type!);

  const onSubmit = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    try {
      const result = await ChronosAuthApi.login({ channel: channel!.trim(), password: password.trim() });
      store.auth.setJid(result.jid);

      console.log('from on submit evt', evt)
      console.log('store auth.referrer', store.auth.referrer)
      console.log('result', result)
      console.log('localStora ref', localStorage.getItem('ref'))
      console.log('localStora id (ri)', localStorage.getItem('ri'))
      
      store.auth.tryRedirectToReferredApp({
        url: store.auth.referrer,
        onRefresh: jid => store.auth.setJid(jid),
        success: () => {
          // const partner = this.$store.getters.partner
          // if (partner && this.$metrika) {
          //   this.$metrika.userParams({
          //     partner: Boolean(partner),
          //     ['partner_id']: partner
          //   })
          //   this.$metrika.params({
          //     ['from_partner']: partner
          //   })
          // }
        },
        fail: (error: any) => {
          console.log('* from login redirect err -', error)
        }
      });

      const userId = store.auth.User?.userId
      gtmSend({'event': 'logIn-successed', 'user_id': userId ? userId : ''})

    } catch (error: any) {
      // let message: string = (t("chronos.auth.loginFailed"));
      // switch (error.message) {
      //   case 'INVALID_CREDENTIALS':
      //     message = t("chronos.auth.wrongPassword");
      //     break;
      //   default:
      //     break;
      // }

      setPasswordMode('error');
      setPasswordMessage(error.message);

    } finally {
      store.setLoading(false);
    }
  }

  return (
    <PageContainer>
      <Form className="form signin" onSubmit={onSubmit}>
        <div className="form-header">
          <AvatarContainer>
            {store.auth.User?.avatarUrl
              ? <img src={store.auth.User?.avatarUrl} />
              : <Avatar size={48} />
            }
          </AvatarContainer>
          <div className="form-title">{greeting}</div>
          <div className="form-subtitle">{channel}</div>
        </div>

        <div className="form-content">

          {(store.auth.User?.type === 'google') && false &&

            <SocialButtons>
              <Button name="google-button" color="transparent" bordered={true} style={{ padding: '0.563rem 0.5rem' }} onClick={authWithGoogle}>
                <ButtonWithIcon><GoogleIcon /><span>{t("chronos.auth.login.signWithGoogle")}</span></ButtonWithIcon>
              </Button>
            </SocialButtons>
          }

          {
            isAuthWithCreditionals &&
            <InputContainer label={t("chronos.auth.login.password")} message={t(passwordMessage)}>
              <Input
                name="password"
                size='big'
                value={password}
                type="password"
                mode={passwordMode}
                readonly
                autoFocus
                onFocus={(e: any) => e.target.removeAttribute('readonly')}
                onChange={(val: string) => setPassword(val)}
              />
            </InputContainer>
          }

          <FormControls className='form-buttons'>
            {isAuthWithCreditionals && <Button size='big' name="submit-button" type="submit" disabled={!password}>{t("chronos.app.autorisation.signIn")}</Button> }
            <Button name="another-button" type="button" size='big' color="transparent" onClick={() => navigate('/signin')}>{t("chronos.auth.login.anotherAccount")}</Button>
          </FormControls>
        </div>
      </Form>
    </PageContainer>
  );
});


const AvatarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  margin: 0 auto 1.687rem auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 0 4px var(--accent-blue);

  img {
    max-width: 100%;
  }
`;
